<template>
  <div>
    <div id="isPartPanel">
      <h3 id="pageTitleContainer">
        <span id="pageNumber">01</span>
        <span id="pageTitle">Aggregator Details</span>
      </h3>
      <div class="panelTitle">
        Is your agency part of a Cluster, Aggregator, Alliance, or another group that receives a
        portion of the agency’s revenue?
      </div>
      <div>
        <div style="margin-left: 32px;margin-top: 16px">
          <v-container
            class="px-0"
            fluid
          >
            <v-radio-group v-model="partOfAggregator">
              <v-radio
                v-for="option in options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              ></v-radio>
            </v-radio-group>
          </v-container>
        </div>
      </div>
    </div>

    <div id="carrierSelectPanel" v-if="showSelectCarriers">
      <div class="panelTitle">
        Which Carriers do you access through your Group?
      </div>

      <div>
        <v-autocomplete
          v-model="selectedCarriers"
          :loading="loading.carriersList || loading.selectedCarriers"
          :items="carriersList"
          :search-input.sync="carrierSearch"
          class="mx-4"
          cache-items
          :label="carrierLabel"
          flat
          chips
          multiple
          item-text="attributes.name"
          item-value="id"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeCarrierChip(data.item.id)"
            >
              {{ data.item.attributes.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>

      <div class="ml-4">
        <small>* You can select from the most common or type to search for specific carriers.</small>
      </div>
    </div>

    <div id="ratePanel" v-if="showEditRates">
      <h3 id="ratePanelTitle" class="panelTitle">
        What are your Group's monthly costs?
      </h3>
      <div>
        <table style="border:none;">
          <tbody>
          <tr>
            <td style="width:90px;">
              <v-text-field suffix="%" v-model="aggregator_options.aggregator_percentage"
                            :placeholder="!allowEditPercent ? '------------' : 'Ex: 5'"
                            :disabled="!allowEditPercent"
                            :error-messages="getFieldErrors('aggregator_percentage')"
                            :error="fieldHasErrors('aggregator_percentage')"
                            @input="updateAggregatorPercentage"
              />
            </td>
            <td
              style="padding-left:40px;padding-right:40px; font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 18px;line-height: 28px;padding-top:16px;">
              OR
            </td>
            <td style="width:90px;">
              <v-text-field prefix="$" v-model="aggregator_options.aggregator_flat_rate"
                            :placeholder="!allowEditFlat ? '------------' : 'Ex: 200'"
                            :disabled="!allowEditFlat"
                            :error-messages="getFieldErrors('aggregator_flat_rate')"
                            :error="fieldHasErrors('aggregator_flat_rate')"
                            @input="updateAggregatorFlatRate"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="nextButtonPanel">
      <v-btn
        @click.prevent="saveAndContinue"
        color="primary"
        :disabled="!canHitNext"
        :loading="saving"
      >
        Save & Continue
      </v-btn>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HasFormErrors from "@/config/mixins/HasFormErrors";
import store from "@/config/store";
import _ from "lodash";

export default {
  mixins: [HasFormErrors],
  data() {
    return {
      carrierSearch: '',
      preloadedCarriers: [],
      selectedCarriers: [],
      loading: {
        carriersList: true,
        selectedCarriers: true,
        percentage: true
      },
      saving: false,
      partOfAggregator: null,
      carriers: [],
      featuredCarriers: [],
      aggregator_options: {
        aggregator_rate_type: '',
        aggregator_percentage: '',
        aggregator_flat_rate: '',
      },
      options: [
        {
          value: true,
          label: "Yes, I am part of a group that receives a portion of the Agency's revenue."
        }, {
          value: false,
          label: "No, I am not part of a group that receives a portion of the Agency's revenue."
        }
      ]
    }
  },
  watch: {
    carrierSearch: _.debounce(function (val) {
      this.loadCarriers();
    }, 500),
  },
  methods: {
    ...mapActions({
      loadCommissionSetup: 'commissions/loadCommissionSetup',
    }),
    updateAggregatorPercentage(val) {
      if (val) {
        this.aggregator_options.aggregator_rate_type = 'percentage';
      } else {
        this.aggregator_options.aggregator_rate_type = '';
      }

      this.clearFormException();
    },
    updateAggregatorFlatRate(val) {
      if (val) {
        this.aggregator_options.aggregator_rate_type = 'flat';
      } else {
        this.aggregator_options.aggregator_rate_type = '';
      }

      this.clearFormException();
    },
    saveAndContinue() {
      this.saving = true;
      this.$api.commissionSetup.saveAggregator({
        has_aggregator: this.partOfAggregator,
        aggregator_carriers: this.selectedCarriers,
        aggregator_flat_rate: this.aggregator_options.aggregator_flat_rate,
        aggregator_percentage: this.aggregator_options.aggregator_percentage,
        aggregator_rate_type: this.aggregator_options.aggregator_rate_type,
      }).then((response) => {
        return store.dispatch('loadAccount').then(() => {
          this.$emit('goToStep', 2);
        });
      }).catch((error) => {
        this.setFormException(error);
        this.saving = false;
      });
    },
    loadCarriers() {
      this.loading.carriersList = true;
      this.$api.commissionSetup.aggregatorCarrierSearch({search: this.carrierSearch}).then(({data}) => {
        this.carriers = data;
        this.loading.carriersList = false;
      });
    },
    loadAggregatorPercentage() {
      this.aggregator_options.aggregator_rate_type = this.commissionSetup.aggregator_rate_type;
      this.aggregator_options.aggregator_percentage = this.commissionSetup.aggregator_percentage;
      this.aggregator_options.aggregator_flat_rate = this.commissionSetup.aggregator_flat_rate;

      this.loading.percentage = false;
    },
    loadSavedCarriers() {
      this.$api.commissionsAggregatorCarriers.index().then(({data}) => {
        this.preloadedCarriers = data;
        this.selectedCarriers = this.preloadedCarriers.map((carrier) => {
          return carrier.id;
        });
        this.loading.selectedCarriers = false;
      });
    },
    loadFeaturedCarriers() {
      this.$api.commissionsAggregatorCarriers.featured().then(({data}) => {
        this.featuredCarriers = data;
      });
    },
    removeCarrierChip(carrier) {
      this.selectedCarriers = this.selectedCarriers.filter((item) => item !== carrier);
    },
  },
  mounted() {
    this.loadCommissionSetup().then(() => {
      this.partOfAggregator = this.commissionSetup.has_aggregator;
      this.loadFeaturedCarriers();
      this.loadCarriers();
      this.loadSavedCarriers();

      if (this.partOfAggregator) {
        this.loadAggregatorPercentage();
      } else {
        this.loading.percentage = false;
      }
    })
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
    }),
    carriersList() {
      //todo:: make sure are unique and sorted by name
      return _.sortBy(_.uniq([
        ...this.featuredCarriers,
        ...this.carriers,
        ...this.preloadedCarriers
      ], 'id'), function (carrier) {
        return carrier.name;
      });
    },
    carrierLabel() {
      if (this.selectedCarriers.length === 0) {
        return 'Select Carriers...';
      }
    },
    canHitNext() {
      if (this.partOfAggregator === null) {
        return false
      } else if (this.partOfAggregator === false) {
        return true
      }

      return this.showEditRates;
    },
    showSelectCarriers() {
      return this.partOfAggregator;
    },
    showEditRates() {
      return this.selectedCarriers.length > 0 && this.partOfAggregator;
    },
    allowEditPercent() {
      if (this.loading.percentage) {
        return false;
      }

      return this.aggregator_options.aggregator_rate_type !== 'flat';
    },
    allowEditFlat() {
      if (this.loading.percentage) {
        return false;
      }

      return this.aggregator_options.aggregator_rate_type !== 'percentage';
    }
  }
}
</script>

<style lang="scss" scoped>
#isPartPanel, #carrierSelectPanel {
  background: #FFFFFF;
  padding: 16px 32px;
  margin-bottom: 8px;
}

::v-deep .v-select__selections {
  overflow-y: auto;
  max-height: 165px;
}

#nextButtonPanel {
  background: #FFFFFF;
  padding: 16px 32px;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}

#ratePanel {
  background: #FFFFFF;
  padding: 16px 32px;
  margin-bottom: 8px;
}

.panelTitle {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #3A3541;
}

td {
  vertical-align: top;
}
</style>
